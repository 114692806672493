<template>
  <div class="bookings">
    <div class="card full-page">
      <div class="card-body inner-scroll">
        <div v-if="hasAccess" class="row" style="height: 100%">
          <div class="col-md-5">
            <p class="feature-text">{{ $t('desc_feat_widget_1') }}</p>
            <p class="feature-text">{{ $t('desc_feat_widget_2') }}</p>
            <p class="feature-text">{{ $t('desc_feat_widget_3') }}</p>

            <h4>{{ $t('Language') }}</h4>
            <v-select
              v-model="language"
              :clearable="false"
              label="title"
              :options="[
                { title: 'Español', value: 'ES' },
                { title: 'English', value: 'EN' },
              ]"
              :reduce="option => option.value"
            />
            <br />
            <h4>Iframe code</h4>
            <pre>
&lt;iframe src="{{
                'https://book.toddl.co/book/' + $store.state.auth.user.slug + '?lang=' + language
              }}" width="400" height="400"&gt;&lt;/iframe&gt;</pre
            >
            <br />
            <h4>Direct link</h4>
            <pre>{{
              'https://book.toddl.co/book/' + $store.state.auth.user.slug + '?lang=' + language
            }}</pre>
          </div>
          <div class="col-md-7">
            <iframe
              class="widget"
              :src="
                'https://book.toddl.co/book/' + $store.state.auth.user.slug + '?lang=' + language
              "
              height="100%"
              width="100%"
            />
          </div>
        </div>
        <div v-else class="row" style="height: 100%">
          <div class="col-md-5">
            <p class="feature-text">{{ $t('blocked_feat_widget_1') }}</p>
            <p class="feature-text">{{ $t('blocked_feat_widget_2') }}</p>
            <p class="feature-text">{{ $t('blocked_feat_widget_3') }}</p>
          </div>
          <div class="col-md-7">
            <iframe
              class="widget"
              :src="'https://book.toddl.co/book/toddlco?lang=' + language"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div v-if="!hasAccess" class="card-footer footer-shadow">
        <router-link to="/subscribe" class="btn btn-toddl">
          {{ $t('upgrade_plan_cta') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BButton, BFormTextarea, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import NotFound from '@/components/NotFound'
import userCan from '@/libs/user-can'

export default {
  name: 'Messages',
  components: {
    NotFound,
    BDropdown,
    BButton,
    BDropdownItem,
    BFormGroup,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      language: 'ES',
    }
  },
  computed: {
    hasAccess: function () {
      return userCan(this.$store.state.auth.user.plan, 'widget')
    },
  },
}
</script>

<style scoped>
.feature-text {
  font-size: 1.2rem;
  color: #333;
}

iframe {
  border: 1px solid #ccc;
}
pre {
  padding: 1em;
  font-size: 1.2em;
  font-family: 'Courier New', monospace;
  color: #4b64a5;
}

.subscribe-button {
  background-color: #4b64a5;
  padding: 0.75em 1em;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.2em;
  cursor: pointer;
  color: #fff !important;
  margin-right: 1em;
}

@media (max-width: 648px) {
  .widget {
    margin-top: 3em;
    max-width: 100%;
  }
}
</style>
